@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import url("https://cdn.syncfusion.com/ej2/25.2.4/material3.css");
// Excel Viewer
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-documenteditor/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-icons/styles/material.css";

$base-color: #0050ffcc;
$dark-blue: #17375D;

* {
  font-family: sans-serif !important;
}

.ant-avatar > img {
  object-fit: contain;
}

.hidden {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.ant-modal-confirm {
  width: 420px !important;
  .ant-modal-body {
    padding: 15px;

    .ant-modal-confirm-content {
      margin-top: 0;
    }

    .ant-modal-confirm-btns {
      margin-top: 15px;

      .ant-btn {
        &:first-child {
          color: #717579;
          background: transparent;
          box-shadow: none;

          &:hover {
            background: transparent;
            color: #717579;
            border: 1px solid #717579;
          }

          &:active {
            background: transparent;
            color: #717579;
            border: 1px solid #717579;
          }

          &:focus {
            background: transparent;
            color: #717579;
            border: 1px solid #717579;
          }
        }

        &:last-child {
          background: $base-color;
          border-color: $base-color;

          &:hover {
            background: #756399;
            color: #fff;
          }

          &:active {
            background: $base-color;
            color: #fff;
          }

          &:focus {
            background: $base-color;
            color: #fff;
          }

          &[disabled] {
            background: #e7e7e7;
            color: #616161;
          }
        }
      }
    }
  }

  &.content_mt {
    .ant-modal-body {
      .ant-modal-confirm-content {
        margin-top: 5px;
      }
    }
  }
}

.filter_icon {

  svg {
    width: 21px;
    height: 22px;
  }

  &.apply {
    svg {
      path {
        fill: $base-color;
      }
    }

    &.blue {
      svg path {
        fill: $base-color;
      }
    }
  }

  &.clear svg path {
      fill: #717579;
  }
}
.sales_filter_icon {
  &.apply {
       svg {
         width: 21px;
         height: 22px;

         path {
           fill: $dark-blue;
         }
       }

       &.blue {
         svg path {
           fill: $dark-blue;
         }
       }

       &.clear svg {
        width: 20px;
        height: 21px;

        path {
          fill: #717579;
        }
      }
 }

   &.clear svg {
     width: 20px;
     height: 21px;

     path {
       fill: #717579;
     }
   }
 }

.sidebar_subs {
  height: 30px !important;
  display: flex;
  align-items: center;
}


.ant-select-dropdown {
  border: 1px solid #c9c9c9!important;

  .ant-select-item {
    height: 20px!important;
    min-height: 20px!important;
    padding: 0 12px!important;;
    font-size: 12px!important;;
  }
}

.download_popover {

  .ant-popover-inner-content {
    padding: 5px 10px;
  }
}

//
//.ant-image-preview-operations .ant-image-preview-operations-operation:nth-child(4),
//.ant-image-preview-operations .ant-image-preview-operations-operation:nth-child(5){
//  display: none;
//}

.ant-image-preview-img {
  max-height: 70vh;
}

.ant-image-preview-mask {
  background-color: #000000d9;
}


.tox-statusbar__branding {
  display: none;
}

.e-spinner-inner {
  display: none !important;
}

.gray_option {
  background: #eee;
}

.full_height_spin{
  height:100%;

  .ant-spin-container{
    height:100%;
  }

  .ant-carousel{
    width:inherit;
  }
}

.broker_item{
  display: flex;
  .ant-dropdown-menu-title-content{
    display: flex;
    align-items: center;
    justify-self: space-between;
  }
}

@media screen and (max-width: 875px) {
  .broker_menu{
    padding-top: 0px !important;
    & svg{
      width: 20px;
      height:20px;
    }
  }
  .broker_menu li:nth-child(1){
    border-bottom: 1px solid #c5c5c5;
  }
}


.modal_container {

  &.hide_cancel {
    .ant-btn-default {
      display: none;
    }
  }
}

.reverse-panel{
  flex-direction: column-reverse !important;
  display: flex !important;
}

.custom-overlay {
  .ant-col,
  .ant-dropdown-menu,
  .ant-dropdown-menu-submenu-title {
    padding: 0px !important;
  }
  li + li{
    border-top: 1px solid #e6e6e6 !important;
  }
  li {
    padding:  0px !important;
  }
  button{
    width:100% !important;
    height:26px !important;
    display: flex !important;
    justify-content: flex-start !important;
    padding: 3px 20px !important
  }
}

.center-slider {
  .slick-track{
    display: flex;
    align-items: center;
  }
}

.center_text_dropdown {
  .ant-select-item-option-content {
    text-align: center;
  }
}

.rc-virtual-list-holder::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
.rc-virtual-list-holder::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.3);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.e-icons {
  font-family: "e-icons" !important;
}
